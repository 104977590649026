<template>
  <b-modal
    :id="id"
    :no-close-on-backdrop="noAutoClose"
    :no-close-on-esc="noAutoClose"
    :hide-header-close="noAutoClose"
    :centered="centered"
    :scrollable="scrollable"
    :size="modalSize"
    @hide="onHidden"
    @shown="onShow"
    :hide-footer="hideFooter"
    :hide-header="hideHeader">
    <template #modal-title>
      <slot name="title">
        Using <code>$bvModal</code> Methods
      </slot>
    </template>
    <slot name="body">
      <div class="d-block text-center">
        <h3>Hello From This Modal!</h3>
      </div>
      <b-button class="mt-3" block @click="close">Close Me</b-button>
    </slot>
    <template #modal-footer>
      <slot name="footer">
        <b-button class="mt-3" block @click="close">Close Me</b-button>
      </slot>
    </template>
  </b-modal>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';

export default {
  name: 'ModalWrapper',
  props: {
    id: String,
    hideFooter: Boolean,
    hideHeader: Boolean,
    noAutoClose: Boolean,
    centered: Boolean,
    scrollable: Boolean,
    onModalClose: Function,
    size: String,
    onModalHidden: Function,
    onModalShow: Function,
  },
  computed: {
    modalSize() {
      return this.size || 'sm';
    },
  },
  methods: {
    close() {
      if (isNotEmpty(this.onModalClose)) {
        this.onModalClose();
      }
      this.$bvModal.hide(this.id);
    },
    // eslint-disable-next-line no-unused-vars
    onShow(bvModal) {
      if (isNotEmpty(this.onModalShow)) {
        console.log('onModalShow', this.id);
        this.onModalShow();
      }
    },
    // eslint-disable-next-line no-unused-vars
    onHidden(bvModal) {
      if (isNotEmpty(this.onModalHidden)) {
        console.log('onModalHidden', this.id);
        this.onModalHidden();
      }
      // console.log('onHidden', bvModal);
      // bvModal.preventDefault();
    },
  },
};
</script>

<style scoped>

</style>
